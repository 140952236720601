import React from 'react';
import styled from 'styled-components';
import { TwoCol } from '../style/TwoCol';
import { Color } from '../style/Color';
import { dropShadow } from '../style/dropShadow';
import { InternalPage } from '../style/InternalPage';

export type Props = {};

const Holder = styled.div``;

const MapHolder = styled.div`
	background-color: ${Color.BG};
	width: 100%;
	padding: 20px;
	box-sizing: border-box;

	iframe {
		border: 8px solid ${Color.HILIGHT};
		box-sizing: border-box;
		${dropShadow};
		width: 100%;
		height: 250px;
	}
`;

const Component: React.FC<Props> = props => {
	const mapsURL = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.5820237574603!2d-74.0131655!3d40.70520219999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a115199d355%3A0xbc072cafbcb9cad1!2s26%20Broadway%205th%20floor%2C%20New%20York%2C%20NY%2010004!5e0!3m2!1sen!2sus!4v1641390152915!5m2!1sen!2sus`;

	return (
		<InternalPage>
			<Holder>
				<section>
					<h1>Contact</h1>
					<TwoCol>
						<div>
							<p>
								26 Broadway, 14th Floor
								<br/>
								New York, NY 10004
							</p>
						</div>
						<div>
							<p>
								Phone:{' '}
								<a className='inline' href='tel:212-627-9898'>
									(212) 627-9898
								</a>
								<br />
								Email:&nbsp;
								<a className='inline' href='mailto:office@slosslaw.com'>
									office@slosslaw.com
								</a>
								{/* <br />
								Fax (212) 627-9498 */}
							</p>
							<p></p>
						</div>
					</TwoCol>
					<MapHolder>
						<iframe src={mapsURL} />
					</MapHolder>
				</section>
			</Holder>
		</InternalPage>
	);
};

export default Component;
